import {graphql} from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useTranslation, Trans, } from 'gatsby-plugin-react-i18next';

import icon from '../images/icon.png'
import { StaticImage } from 'gatsby-plugin-image';

import EnAppleBadgeSVG from "../assets/en_AppStore_Badge.svg"
import DeAppleBagdeSVG from "../assets/de_AppStore_Badge.svg"

const enGoogleBadge = (
  <a href='https://play.google.com/store/apps/details?id=com.present_helper.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="140" height="55" />
  </a>
)

const deGoogleBadge = (
  <a href='https://play.google.com/store/apps/details?id=com.present_helper.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
    <img alt='Jetzt bei Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' width="125" height="50" />
  </a>
)

const usAppleBagde = (
  <a href='https://apps.apple.com/us/app/present-helper/id6450317676'>
    {/* <img alt='Laden im App Store' src='images/svg/us_AppStore_Bagde.svg' /> */}
    <EnAppleBadgeSVG style={{height:50, width: 125}} />
  </a>
)

const deAppleBagde = (
  <a href='https://apps.apple.com/de/app/present-helper/id6450317676'>
    {/* <img alt='Laden im App Store' src='images/svg/de_AppStore_Bagde.svg' /> */}
    <DeAppleBagdeSVG style={{height:50, width: 125}} />
  </a>
)

const enDescriptionFirst = (
  <>
    <p>
      Ae you in search of the perfect gift idea? Forget the guesswork – Present Helper is here to assist you!
    </p>
    <p>
      With just a few clicks, you'll always find the right gift. Through an interactive brainstorming process that takes into account the recipient's likes and interests, Present Helper delivers customized gift suggestions right on your screen.
    </p>
  </>
)
const enDescription = (
  <>
    <p>
      Your benefits at a glance:
    </p>
    <ul>
      <li>
        <b>Personalized Gift Ideas:</b> Save your brainstorming and get presented with matching gifts.
      </li>
      <li>
        <b>Reminder Function:</b> You'll never forget a gift again! Receive a notification in good time before the birthday or occasion.
      </li>
      <li>
        <b>Store Your Own Ideas:</b> Add your own gift ideas and keep track of everything.
      </li>
      <li>
        <b>Constant Expansion:</b> Our gift database is constantly growing – there's something for every hobby!
      </li>
    </ul>

    <p>
      Create profiles for your loved ones and save their interests and preferences. This way, you can find the perfect gift with just one click, without racking your brain. Have a new idea or heard the person wants something specific? Simply jot it down in the app, and you'll have everything in one place.
    </p>

    <p>
      Download Present Helper now and make gift finding a breeze!
    </p>
  </>
)

const deDescritpionFirst = (
  <>
    <p>
      Suchst du nach der perfekten Geschenkidee? Vergiss das Rätselraten – Present Helper ist hier, um dir zu helfen!
    </p>
    <p>
      Mit nur wenigen Klicks findest du immer das passende Geschenk. Durch einen interaktiven Brainstormingprozess, der die Vorlieben und Interessen der beschenkten Person berücksichtigt, liefert Present Helper maßgeschneiderte Geschenkvorschläge direkt auf deinen Bildschirm.
    </p>
  </>
)
const deDescritpion = (
  <>
    <p>
      Deine Vorteile auf einen Blick:
    </p>
    <ul>
      <li>
        <b>Individuelle Geschenkideen:</b> Speichere dein Brainstorming und lass dir passende Geschenke präsentieren.
      </li>
      <li>
        <b>Erinnerungsfunktion:</b> Du wirst nie wieder ein Geschenk vergessen! Bekomme rechtzeitig vor dem Geburtstag oder dem Anlass eine Benachrichtigung.
      </li>
      <li>
        <b>Eigene Ideen hinterlegen:</b> Füge deine eigenen Geschenkideen hinzu und behalte den Überblick.
      </li>
      <li>
        <b>Ständige Erweiterung:</b> Unsere Geschenkdatenbank wächst ständig – es ist für jedes Hobby etwas dabei!
      </li>
    </ul>

    <p>
      Erstelle Profile für deine Lieben und speichere deren Interessen und Vorlieben. So kannst du mit nur einem Klick das perfekte Geschenk finden, ohne dir den Kopf zu zerbrechen. Hast du eine neue Idee oder hörst du, dass die Person etwas bestimmtes möchte? Einfach in der App notieren und du hast alles an einem Ort.
    </p>

    <p>
      Lade Present Helper jetzt herunter und mach das Geschenkefinden zum Kinderspiel!
    </p>
  </>
)

const deScreenshots = (
  <div style={{display: "flex", flexWrap:"wrap", justifyContent:"space-between" ,alignItems:"center" ,marginBottom: "15px"}}>
        <StaticImage 
          src="../images/app/de/screenshot1.png"
          alt='Present Helper App Screenshot 1'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginRight: "15px", marginBottom: "15px"}}
        />
        <StaticImage 
          src="../images/app/de/screenshot2.png"
          alt='Present Helper App Screenshot 2'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginRight: "15px", marginBottom: "15px"}}
        />
        <StaticImage 
          src="../images/app/de/screenshot3.png"
          alt='Present Helper App Screenshot 3'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginBottom: "15px"}}
        />
      </div>
)

const enScreenshots = (
  <div style={{display: "flex", flexWrap:"wrap", justifyContent:"space-between" ,alignItems:"center" ,marginBottom: "15px"}}>
        <StaticImage 
          src="../images/app/en/screenshot1.png"
          alt='Present Helper App Screenshot 1'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginRight: "15px", marginBottom: "15px"}}
        />
        <StaticImage 
          src="../images/app/en/screenshot2.png"
          alt='Present Helper App Screenshot 2'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginRight: "15px", marginBottom: "15px"}}
        />
        <StaticImage 
          src="../images/app/en/screenshot3.png"
          alt='Present Helper App Screenshot 3'
          placeholder="blurred"
          // layout="fixed"
          width={200}
          style={{boxShadow: "5px 5px 5px ", marginBottom: "15px"}}
        />
      </div>
)

const App = (props) => {
  const {t} = useTranslation();
  const { i18n } = useTranslation();
  const language = i18n.language
  return (
    <Layout>
      <SEO title={t('appMetaTitle')} description={t('appMetaDescription')}/>
      <div style={{display: "flex", flexDirection:"row", justifyContent: "center"}}>
        <img
          src={icon}
          style={{
            width: "200px",
            borderRadius: "20px",
            boxShadow: "3px 3px 1px #ccc",
          }}
          alt='Present Helper Icon'
        />
      </div>
      <h1 style={{textAlign:"center", marginBottom: "3rem"}}>
        <Trans>The App</Trans>
      </h1>
      <div style={{display:"flex", justifyContent: "space-evenly", flexWrap:"wrap",}}>
        <div style={{}}>
          {language === 'de' ? deGoogleBadge : enGoogleBadge}
        </div>
        <div>
          {language === 'de' ? deAppleBagde : usAppleBagde}
        </div>
      </div>
      <div>
        {language === 'de' ? deDescritpionFirst : enDescriptionFirst}
      </div>
      {language === 'de' ? deScreenshots : enScreenshots}
      <div>
        {language === 'de' ? deDescritpion : enDescription}
      </div>
      <section>
        <h2 id='support'>Support</h2>
        <p>
          <Trans i18nKey="problems">
            Do you have a problems with the app, you have feedback or do you need any help?
          </Trans>
        </p>
        <p>
          <Trans>
            Contact us!
          </Trans>
        </p>
        <p>
        <b>E-Mail:</b> info@present-helper.com
        </p>

      </section>
    </Layout>
  );
};

export default App;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "app"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
